import I18N from '@/i18n';
import type { RowProps } from 'antd';
import { Col, Row, Tooltip } from 'antd';
import type { FC, ReactNode } from 'react';
import IconFontIcon from '@/components/Common/IconFontIcon';

export type Order = {
  key: string;
  ascend: boolean;
};
export interface SortProps extends RowProps {
  order: Order;
  orderKey?: string;
  label: ReactNode;
  onSort?: (order: Order) => void;
  showSortTooltip?: boolean;
  arrow?: (key: string, order: Order) => ReactNode;
}

/**
 * 待排序功能的title
 * @param props
 * @constructor
 */
const SortTitle: FC<SortProps> = (props) => {
  const {
    orderKey,
    order,
    label,
    onClick,
    showSortTooltip = true,
    onSort,
    arrow = (_orderKey, _order) => {
      if (_order.key === _orderKey) {
        if (_order.ascend) {
          return <IconFontIcon iconName="asc" />;
        }
        return <IconFontIcon iconName="desc" />;
      }
      return null;
    },
    ...others
  } = props;
  if (!orderKey) {
    return label;
  }
  const _arrow = arrow(orderKey, order);
  const innerNode = (
    <Row
      style={{ cursor: onSort && 'pointer' }}
      align="middle"
      wrap={false}
      {...others}
      onClick={(e) => {
        if (onSort) {
          e.preventDefault();
          onSort({
            key: orderKey,
            ascend: order.key === orderKey ? !order.ascend : true,
          });
        } else {
          onClick?.(e);
        }
      }}
    >
      <Col flex={1} style={{ overflow: 'hidden' }}>
        {label}
      </Col>
      {_arrow && <Col style={{ fontSize: '12px', color: '#999' }}>{_arrow}</Col>}
    </Row>
  );

  if (onSort && showSortTooltip) {
    return (
      <Tooltip title={order.ascend ? I18N.t('点击降序') : I18N.t('点击升序')}>{innerNode}</Tooltip>
    );
  }
  return innerNode;
};
export default SortTitle;
