// @i18n-ignore

import en from './../../translate/locales/';
import oem from './../../translate/locales/oem';
import { isElectron, sendAsync, sendSync } from '@/utils/ElectronUtils';
import React from 'react';
import _ from 'lodash';

export type SysPresSettings = {
  autoLaunch?: boolean;
  dataDir?: string;
  videoDir?: string;
  rpaDir?: string;
  appLang?: string; // 客户端语言
  lang: string; // 浏览器界面语言，auto 表示跟随客户端
  devtools: boolean;
  gpu: boolean;
  sandbox: boolean;
  winSyncEvtDelay?: number;
  winSyncPauseKey?: string;
  preventSleep: boolean;
  exitOnClose: boolean;
  autoUpdate: number | boolean;
  autoInstallTimeRange?: string;
  hideNewVersionAlert: boolean;
  localFrontendProxyEnabled?: boolean;
  localFrontendProxyMode?: 'system' | 'custom';
  localFrontendProxyUrl?: string;
  openBrowserIntervalSecMin?: number;
  openBrowserIntervalSecMax?: number;
  autofill?: 'none' | 'username' | 'password';
};
let sysPresSettings: SysPresSettings | null = null;
/**
 * 获取系统偏好设置
 */
export const getSysPresSettings = () => {
  if (sysPresSettings) return sysPresSettings;
  if (isElectron()) {
    // 就客户端没有值的话，就会导致保存会切换语言，但只有一次
    sysPresSettings = { appLang: 'zh-CN', ...sendSync('get-sys-pres', {}) };
  } else {
    sysPresSettings = {
      appLang: localStorage.getItem(LANGUAGE_KEY) || 'zh-CN',
    };
  }

  return sysPresSettings as SysPresSettings;
};

/**
 * 更新系统偏好设置
 * @param settings
 */
export const setSysPresSettings = (settings: SysPresSettings) => {
  if (isElectron()) {
    return sendAsync('set-sys-pres', settings).then((res) => {
      if (res.success) {
        sysPresSettings = settings;
      } else {
        throw new Error(res.message);
      }
    });
  }
  sysPresSettings = settings;
  return Promise.resolve();
};
const LANGUAGE_KEY = 'huayoung-language';

function _isCn() {
  return localStorage.getItem(LANGUAGE_KEY)
    ? localStorage.getItem(LANGUAGE_KEY)?.toLowerCase().startsWith('zh')
    : true;
}
export function i18nInit() {
  if (isElectron()) {
    const { appLang } = getSysPresSettings();
    localStorage.setItem(LANGUAGE_KEY, appLang || 'zh-CN');
  }
  if (!localStorage.getItem(LANGUAGE_KEY)) {
    localStorage.setItem(LANGUAGE_KEY, 'zh-CN');
  }
  const isCn = _isCn();
  const titleBarFaqEl = document.querySelector('#window-title-bar-help-tooltips-faq');
  const titleBarCourseEl = document.querySelector('#window-title-bar-help-tooltips-course');
  if (titleBarFaqEl) {
    document.querySelector('#window-title-bar-help-tooltips-faq')!.innerHTML = isCn
      ? '常见问题'
      : 'FAQ';
  }
  if (titleBarCourseEl) {
    document.querySelector('#window-title-bar-help-tooltips-course')!.innerHTML = isCn
      ? '视频教程'
      : 'Video Courses';
  }
}
const reg = /{{(.*?)}}/g;

const replaceParam = (template: string, params: { [x: string]: any }) => {
  return (
    template?.replace?.(reg, (item, key) => {
      if (_.has(params, key)) {
        return params[key];
      }
      return item;
    }) || template
  );
};
function _setLanguage(type: 'en-US' | 'zh-CN') {
  if (isElectron()) {
    try {
      setSysPresSettings({ ...getSysPresSettings(), appLang: type });
    } catch (e) {
      console.log(e);
    }
  }
  localStorage.setItem(LANGUAGE_KEY, type);
}
export default {
  isCn() {
    return _isCn();
  },
  switchToEn() {
    if (this.isCn()) {
      setTimeout(() => {
        window.location.reload();
      }, 0);
      _setLanguage('en-US');
    }
  },
  switchToCn() {
    if (!this.isCn()) {
      setTimeout(() => {
        window.location.reload();
      }, 0);
      _setLanguage('zh-CN');
    }
  },
  getLocale() {
    if (this.isCn()) {
      return 'zh-CN';
    }
    return 'en-US';
  },
  /**
   *
   * @param string 原始字符串 as key
   * @param params 替换的参数 {}
   * @param i18n_key 非中文状态下的key 可以映射其他翻译文本
   */
  t(string: string, params?: Record<string, any>, i18n_key?: string): any {
    let templateStr = string;
    const _key = i18n_key || string;
    const isCn = this.isCn();
    if (!isCn) {
      if (!_.isNil(en[_key])) {
        templateStr = en[_key];
      } else if (!_.isNil(i18n_key)) {
        templateStr = i18n_key;
      }
    }
    const hasElm = Object.values(params || {}).some((arg) => React.isValidElement(arg));
    const child = replaceParam(templateStr, {
      ...(params || {}),
      ...oem[isCn ? 'zh' : 'en'],
    });
    if (!hasElm) {
      return child;
    }
    const parts = templateStr.split(/(\{\{[^}]+}})/g);

    const nodes = parts.map((part, index) => {
      if (part.match(/\{\{([^}]+)}}/)) {
        const key = part.replace(/\{\{([^}]+)}}/, '$1');
        if (params?.[key]) {
          if (React.isValidElement(params?.[key])) {
            return React.cloneElement(params?.[key], {
              key: index,
            });
          }
          return React.createElement(
            'span',
            {
              key: index,
            },
            params?.[key],
          );
        }
        // 如果参数不存在，你可以在这里放置一个默认占位符
        return React.createElement(
          'span',
          {
            key: index,
          },
          part,
        );
      }
      return <span key={index}>{part}</span>;
    });

    return <>{nodes}</>;
  },
};
