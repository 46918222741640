import type { FormInstance, InputProps, SelectProps } from 'antd';
import CountrySelector, {
  getAreaCodeByCountryName,
} from '@/components/Common/Selector/CountrySelector';
import _ from 'lodash';
import I18N from '@/i18n';
import type { SelectValue } from 'antd/lib/select';
import DMFormItem from '@/components/Common/DMFormItem';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import validate from '@/utils/validate';
import { ProFormText } from '@ant-design/pro-form';
import { useCallback, useEffect } from 'react';
import AutofillInput from '@/components/Common/AutofillInput';

const PhoneNumberInput = (props: {
  inputProps?: InputProps;
  selectProps?: SelectProps<SelectValue>;
  fieldOptions?: {
    phone?: string;
    areaCode?: string;
  };
  f: FormInstance;
  size?: SizeType;
  autofill?: boolean;
}) => {
  const { inputProps, selectProps, size, f } = props;
  const fieldOptions = {
    phone: 'phone',
    areaCode: 'areaCode',
    ...props.fieldOptions,
  };
  const defaultCountry = f.getFieldValue('country') || 'China';

  useEffect(() => {
    const areaCode = getAreaCodeByCountryName(defaultCountry);
    // 就是要每次都更新
    f.setFieldsValue({
      areaCode,
    });
  }, [defaultCountry]);

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <ProFormText name={'areaCode'} hidden />
      <DMFormItem
        name={'country'}
        initialValue={defaultCountry}
        rules={[{ required: true, message: I18N.t('请选择区号') }]}
      >
        <CountrySelector style={{ width: 130 }} {...selectProps} size={size} />
      </DMFormItem>
      <DMFormItem
        dependencies={[fieldOptions.areaCode]}
        validateTrigger={['onBlur', 'onSubmit']}
        style={{ flex: 1, overflow: 'hidden' }}
        name={fieldOptions.phone}
        rules={[
          {
            validator(rule, value) {
              const areaCode = f.getFieldValue(fieldOptions.areaCode);
              const val = _.trim(value);
              if (!val) {
                return Promise.reject(new Error(I18N.t('请输入您的手机号码')));
              }
              const phoneNumber = `+${areaCode}${val}`;
              return validate.phone(phoneNumber);
            },
          },
        ]}
      >
        <AutofillInput
          autofill
          size={size}
          placeholder={I18N.t('请输入您的手机号码')}
          {...inputProps}
          type={'phone'}
          onSelect={({ username, country, password }) => {
            const values = _.omitBy({ account: username, country, password }, _.isNil);
            f.setFieldsValue(values);
          }}
        />
      </DMFormItem>
    </div>
  );
};
export default PhoneNumberInput;
