// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 通过分享码创建一个流程 PUT /api/rpa/acceptShareFlow */
export async function rpaAcceptShareFlowPut(
  body: API.AcceptShareFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListRpaFlowVo>('/api/rpa/acceptShareFlow', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建一个流程分组 POST /api/rpa/addGroup */
export async function rpaAddGroupPost(
  body: API.AddFlowGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowGroupVo>('/api/rpa/addGroup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 校验某个流程分享码 GET /api/rpa/checkFlowShareCode */
export async function rpaCheckFlowShareCodeGet(
  params: {
    // query
    /** shareCode */
    shareCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCheckShareCodeResponse>('/api/rpa/checkFlowShareCode', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 统计团队流程数量 GET /api/rpa/countFlows */
export async function rpaCountFlowsGet(options?: { [key: string]: any }) {
  return request<API.WebResultint>('/api/rpa/countFlows', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 创建一个流程 POST /api/rpa/create */
export async function rpaCreatePost(
  body: API.CreateRpaFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 从指定的历史版本创建一个流程 POST /api/rpa/createFromHis */
export async function rpaCreateFromHisPost(
  body: API.CreateRpaFlowFromHisRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/createFromHis', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建一个流程共享码 POST /api/rpa/createShareCode */
export async function rpaCreateShareCodePost(
  body: API.CreateRpaFlowShareRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/rpa/createShareCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除一个流程分组 DELETE /api/rpa/deleteGroup/${param0} */
export async function rpaDeleteGroupByGroupIdDelete(
  params: {
    // path
    /** groupId */
    groupId: number;
  },
  options?: { [key: string]: any },
) {
  const { groupId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/rpa/deleteGroup/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取某个流程的配置json用以执行rpa流程 GET /api/rpa/fetchFlowConfigTree/${param0} */
export async function rpaFetchFlowConfigTreeByConfigIdGet(
  params: {
    // path
    /** 注意是mongoId不是mysqlId */
    configId: string;
  },
  options?: { [key: string]: any },
) {
  const { configId: param0, ...queryParams } = params;
  return request<API.WebResultMaplongRpaConfig>(`/api/rpa/fetchFlowConfigTree/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 检查某个流程定义名称是否存在 GET /api/rpa/flow/isNameExists */
export async function rpaFlowIsNameExistsGet(
  params: {
    // query
    /** name */
    name: string;
    /** 不为空表示排除某个flow */
    excludeId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/rpa/flow/isNameExists', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取某个流程基本信息 GET /api/rpa/flow/${param0} */
export async function rpaFlowByRpaFlowIdGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVo>(`/api/rpa/flow/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 删除某个流程 DELETE /api/rpa/flow/${param0} */
export async function rpaFlowByRpaFlowIdDelete(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/rpa/flow/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 检查某个流程是否有子流程 GET /api/rpa/flow/${param0}/checkHasSubFlows */
export async function rpaFlowByRpaFlowIdCheckHasSubFlowsGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultboolean>(`/api/rpa/flow/${param0}/checkHasSubFlows`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取某个流程的配置json GET /api/rpa/flowConfig/${param0} */
export async function rpaFlowConfigByConfigIdGet(
  params: {
    // path
    /** 注意是mongoId不是mysqlId */
    configId: string;
  },
  options?: { [key: string]: any },
) {
  const { configId: param0, ...queryParams } = params;
  return request<API.WebResultRpaConfig>(`/api/rpa/flowConfig/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取团队的流程列表 GET /api/rpa/flows */
export async function rpaFlowsGet(
  params: {
    // query
    /** 允许按名称模糊查找 */
    name?: string;
    /** 过滤流程创建类型，为空表示查询所有 */
    rpaFlowType?: 'FileCopy' | 'Manual' | 'Market' | 'MarketCopy' | 'Shared' | 'TkPack' | 'Tkshop';
    /** 按手机还是浏览器流程过滤，为空表示所有 */
    rpaType?: 'Browser' | 'Mobile';
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** groupId */
    groupId?: number;
    /** 格式为[field_name asc|desc], field_name must in id,name,create_time,update_time,exec_count,last_exec_time,create_type,sort_no */
    orderBy?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultRpaFlowVo>('/api/rpa/flows', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取一个流程附件的下载链接 GET /api/rpa/getAttachmentLink */
export async function rpaGetAttachmentLinkGet(
  params: {
    // query
    /** attachmentPath */
    attachmentPath: string;
    /** contentDisposition */
    contentDisposition?: string;
    /** contentType */
    contentType?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/rpa/getAttachmentLink', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据bizCode获取某个流程基本信息 GET /api/rpa/getRpaFlowByBizCode */
export async function rpaGetRpaFlowByBizCodeGet(
  params: {
    // query
    /** bizCode */
    bizCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/getRpaFlowByBizCode', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取流程分组列表 GET /api/rpa/groups */
export async function rpaGroupsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListRpaFlowGroupVo>('/api/rpa/groups', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 从市场上导入一个流程 PUT /api/rpa/importMarketFlow */
export async function rpaImportMarketFlowPut(
  body: API.ImportMarketFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/importMarketFlow', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检查某个分组名称是否存在 GET /api/rpa/isGroupNameExists */
export async function rpaIsGroupNameExistsGet(
  params: {
    // query
    /** name */
    name: string;
    /** 分组改名时用到，检查重复时排除自己 */
    excludeId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/rpa/isGroupNameExists', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发布某个流程 PUT /api/rpa/publish */
export async function rpaPublishPut(
  body: API.PublishFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/publish', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑一个流程的基本信息。 POST /api/rpa/updateFlow */
export async function rpaUpdateFlowPost(
  body: API.UpdateRpaFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/updateFlow', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更改一个流程的所属分组。该接口允许流程在非编辑状态时调用。 POST /api/rpa/updateFlowGroup */
export async function rpaUpdateFlowGroupPost(
  body: API.UpdateRpaFlowGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/updateFlowGroup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更新一个流程分组 POST /api/rpa/updateGroup */
export async function rpaUpdateGroupPost(
  body: API.UpdateFlowGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowGroupVo>('/api/rpa/updateGroup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 当一个流程处于发布状态时更新其：名称，描述，分组信息。其它属性设置了无意义 POST /api/rpa/updatePublishedBasic */
export async function rpaUpdatePublishedBasicPost(
  body: API.UpdateRpaFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRpaFlowVo>('/api/rpa/updatePublishedBasic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 上传一个流程附件，返回附件的路径 POST /api/rpa/uploadAttachment */
export async function rpaUploadAttachmentPost(
  body: {
    /** 该附件是不是元素库预览图 */
    elPreview?: boolean;
    /** file */
    file?: string;
    /** flowId */
    flowId?: number;
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  if (files) {
    formData.append('file', files[0] || '');
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request<API.WebResultstring>('/api/rpa/uploadAttachment', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...(options || {}),
  });
}

/** 检查某个流程定义是不是被计划关联了 GET /api/rpa/${param0}/checkFlowBoundByPlan */
export async function rpaByRpaFlowIdCheckFlowBoundByPlanGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultboolean>(`/api/rpa/${param0}/checkFlowBoundByPlan`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 重新编辑某个流程，即将该流程变成草稿状态 PUT /api/rpa/${param0}/draft */
export async function rpaByRpaFlowIdDraftPut(
  params: {
    // query
    /** version */
    version: string;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVo>(`/api/rpa/${param0}/draft`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个流程的共享状态 GET /api/rpa/${param0}/findSharingResult */
export async function rpaByRpaFlowIdFindSharingResultGet(
  params: {
    // query
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultPageResultRpaFlowShareVo>(`/api/rpa/${param0}/findSharingResult`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 查询某个流程相关的有效分享码 GET /api/rpa/${param0}/findValidShareCodeByFlowId */
export async function rpaByRpaFlowIdFindValidShareCodeByFlowIdGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultListRpaFlowShareCodeVo>(
    `/api/rpa/${param0}/findValidShareCodeByFlowId`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 获取某个流程的最新版本 GET /api/rpa/${param0}/getLatestVersion */
export async function rpaByRpaFlowIdGetLatestVersionGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVersionVo>(`/api/rpa/${param0}/getLatestVersion`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取某个流程的子流程的最新版本 GET /api/rpa/${param0}/getLinkSubFlowLatestVersion */
export async function rpaByRpaFlowIdGetLinkSubFlowLatestVersionGet(
  params: {
    // query
    /** subFlowId */
    subFlowId: number;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVersionVo>(`/api/rpa/${param0}/getLinkSubFlowLatestVersion`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个流程的历史版本列表（无法获取分享流程的历史版本） GET /api/rpa/${param0}/getVersionHis */
export async function rpaByRpaFlowIdGetVersionHisGet(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultListRpaFlowVersionVo>(`/api/rpa/${param0}/getVersionHis`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 用于重新编辑流程时校验某个版本号名称是否已经存在了 GET /api/rpa/${param0}/isVersionExists */
export async function rpaByRpaFlowIdIsVersionExistsGet(
  params: {
    // query
    /** version */
    version: string;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultboolean>(`/api/rpa/${param0}/isVersionExists`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 停止给某个团队的流程共享，该操作会导致目标流程被删除 PUT /api/rpa/${param0}/stopAFlowShare */
export async function rpaByRpaFlowIdStopAFlowSharePut(
  params: {
    // query
    /** 要被删除的目标流程Id */
    targetFlowId: number;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/rpa/${param0}/stopAFlowShare`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 编辑一个rap流程配置json POST /api/rpa/${param0}/updateFlowConfig */
export async function rpaByRpaFlowIdUpdateFlowConfigPost(
  params: {
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  body: API.RpaConfig,
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVo>(`/api/rpa/${param0}/updateFlowConfig`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 将某个市场引用导入的流程更新到最新版本 PUT /api/rpa/${param0}/updateLinkMarketFlowVersion */
export async function rpaByRpaFlowIdUpdateLinkMarketFlowVersionPut(
  params: {
    // query
    /** upgradePlan */
    upgradePlan?: boolean;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVo>(`/api/rpa/${param0}/updateLinkMarketFlowVersion`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 将某个被分享的流程更新到最新状态 PUT /api/rpa/${param0}/updateSharedFlowVersion */
export async function rpaByRpaFlowIdUpdateSharedFlowVersionPut(
  params: {
    // query
    /** upgradePlan */
    upgradePlan?: boolean;
    // path
    /** rpaFlowId */
    rpaFlowId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowId: param0, ...queryParams } = params;
  return request<API.WebResultRpaFlowVo>(`/api/rpa/${param0}/updateSharedFlowVersion`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 使某个流程分享码失效 DELETE /api/rpa/${param0}/removeShareCode */
export async function rpaByRpaFlowShareCodeIdRemoveShareCodeDelete(
  params: {
    // path
    /** rpaFlowShareCodeId */
    rpaFlowShareCodeId: number;
  },
  options?: { [key: string]: any },
) {
  const { rpaFlowShareCodeId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/rpa/${param0}/removeShareCode`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}
