import type { InputProps } from 'antd';
import { Button, Dropdown, Input, Menu, Typography } from 'antd';
import { isElectron, sendAsync } from '@/utils/ElectronUtils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import IconFontIcon from '@/components/Common/IconFontIcon';
import I18N from '@/i18n/I18N';

type AutoFillItem = {
  username: string;
  password?: string;
  country?: string;
  passwordLength?: number;
  createTime: number;
  updateTime: number;
};

export function useAutoFillConfig(type: 'phone' | 'email', autoFetch?: boolean) {
  const [list, setList] = useState<AutoFillItem[]>();
  const refresh = useCallback(() => {
    try {
      sendAsync('autofill/list', {
        type,
      }).then((res) => {
        setList(_.orderBy(res, ['updateTime'], ['desc']));
      });
    } catch (e) {
      setList([]);
    }
  }, [type]);
  useEffect(() => {
    if (autoFetch) {
      refresh();
    }
  }, [autoFetch, refresh]);
  const update = useCallback(
    (item: Pick<AutoFillItem, 'username' | 'password' | 'country'>) => {
      try {
        sendAsync('autofill/update', {
          type,
          ...item,
        }).then(() => {
          refresh();
        });
      } catch (e) {
        console.log(e);
      }
    },
    [refresh, type],
  );
  const remove = useCallback(
    (username: string) => {
      try {
        sendAsync('autofill/remove', {
          type,
          username,
        }).then(() => {
          refresh();
        });
      } catch (e) {
        console.log(e);
      }
    },
    [refresh, type],
  );
  const decrypt = useCallback((password?: string): Promise<string | undefined> => {
    if (!password) {
      return Promise.resolve(undefined);
    }
    try {
      return sendAsync('autofill/decrypt', {
        password,
      });
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }, []);
  return {
    data: list,
    refresh,
    update,
    remove,
    decrypt,
  };
}

const AutofillInput = (
  props: Omit<InputProps, 'onSelect' | 'type'> & {
    onSelect: (item: AutoFillItem) => void;
    autofill?: boolean;
    type: 'phone' | 'email';
  },
) => {
  const { value, onChange, type, autofill, onSelect, ...rest } = props;
  const ref = useRef<any>();
  const flag = useRef(false);
  const changed = useRef(false);
  const input = useMemo(() => {
    return (
      <Input
        ref={ref}
        value={value}
        onChangeCapture={() => {
          changed.current = true;
        }}
        onChange={onChange}
        {...rest}
      />
    );
  }, [onChange, rest, value]);

  const [open, setOpen] = useState(false);
  const close = useCallback(() => {
    setOpen(false);
    ref.current?.focus?.();
  }, []);
  const { data: options, decrypt } = useAutoFillConfig(type, autofill);
  const items = useMemo(() => {
    return options?.filter((item) => {
      if (!_.trim(value) || !changed.current) {
        return true;
      }
      return item.username.includes(_.trim(value));
    });
  }, [options, value]);
  const _onSelect = useCallback(
    (item: AutoFillItem) => {
      if (item.password) {
        decrypt(item.password).then((res) => {
          onSelect({
            ...item,
            password: res,
          });
          close();
        });
      } else {
        onSelect(item);
        close();
      }
    },
    [close, decrypt],
  );
  useEffect(() => {
    if (autofill && options?.length) {
      if (!flag.current) {
        flag.current = true;
        _onSelect(options[0]);
      }
    }
  }, [_onSelect, autofill, decrypt, options]);
  const menu = useMemo(() => {
    return (
      <Menu>
        <div style={{ fontSize: 12, padding: '4px 12px', color: '#999' }}>
          {I18N.t('记住的登录信息')}
        </div>
        {items?.map((item) => {
          return (
            <Menu.Item
              key={item.username}
              onClick={() => {
                _onSelect?.(item);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography.Text style={{ lineHeight: 1.1 }} ellipsis>
                  {item.username}
                </Typography.Text>
                {item.password ? (
                  <Typography.Text style={{ lineHeight: 1.1 }} ellipsis>
                    {'*'.repeat(item.passwordLength || 6)}
                  </Typography.Text>
                ) : undefined}
              </div>
            </Menu.Item>
          );
        })}
        <Button
          size={'small'}
          style={{ position: 'absolute', right: 0, top: 0, padding: 0, zIndex: 2 }}
          type={'text'}
          onClick={close}
        >
          <IconFontIcon size={22} iconName={'guanbi_241'} />
        </Button>
      </Menu>
    );
  }, [_onSelect, close, items]);

  if (isElectron() && !!options?.length && autofill) {
    return (
      <Dropdown
        visible={open && !!items?.length}
        onVisibleChange={(v) => {
          if (v) {
            ref.current?.focus?.();
          }
          setOpen(v);
        }}
        align={{
          offset: [0, -5],
        }}
        arrow
        trigger={['click']}
        overlay={menu}
      >
        {input}
      </Dropdown>
    );
  }
  return input;
};
export default AutofillInput;
