import I18N from '@/i18n';
import type { Ref } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Col, Form, Input, InputNumber, message, Radio, Row, Select, Space, Tooltip } from 'antd';
import type { FormBaseProps } from '@/components/UserCenter/UserSetting';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import FormItem from '@/components/UserCenter/components/FormItem';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { useAutoInstallTimeRangeSettingsModal } from '@/components/UserCenter/components/AutoInstallTimeRangeSettingsModal';
import SwitchLocale from '@/i18n/SwitchLocale';
import {
  getAppBuildNumber,
  getAppProxy,
  getHostName,
  isElectron,
  sendAsync,
  setAppProxy,
} from '@/utils/ElectronUtils';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import useCurrentDevice, { getDeviceSpec } from '@/hooks/useCurrentDevice';
import CopyableText from '@/components/Common/CopyableText';
import HelpLink from '@/components/HelpLink';
import { isWinPlatform } from '@/utils/utils';
import DmSwitch from '@/components/Switch';

const networkTypeEnum = {
  direct: I18N.t('不使用代理'),
  http: I18N.t('使用HTTP代理'),
  socks5: I18N.t('使用SOCKS5代理'),
  system: I18N.t('使用操作系统代理'),
};
const AutofillType = {
  none: I18N.t('不记忆'),
  username: I18N.t('只记忆用户名'),
  password: I18N.t('记忆用户名与密码'),
};
/**
 * 系统偏好设置
 * @param props
 * @param ref
 * @constructor
 */
function SystemPrefForm(
  props: FormBaseProps & {
    hidePreventSleep?: boolean;
    hideExitOnClose?: boolean;
    hideAutoFill?: boolean;
  },
  ref: Ref<unknown> | undefined,
) {
  const {
    editing,
    hidePreventSleep = false,
    hideExitOnClose = false,
    hideAutoFill = false,
  } = props;
  const [v, update] = useState(0);
  const [formIns] = Form.useForm();
  const device = useCurrentDevice();
  const showAutoInstallTimeRangeSettingsModal = useAutoInstallTimeRangeSettingsModal();
  const sysPresSettings = useMemo(() => getSysPresSettings(), []);
  const proxyConfig = useMemo(() => {
    const { mode = 'direct', proxyRules = '', useBackupEndpoint = false } = getAppProxy();
    return {
      proxyMode: ['direct', 'system'].includes(mode) ? mode : proxyRules.split('://')[0],
      proxyHost: proxyRules ? proxyRules.split('://')[1].split(':')[0] : undefined,
      proxyPort: proxyRules ? proxyRules.split('://')[1].split(':')[1] : undefined,
      useBackupEndpoint,
    };
  }, []);

  useEffect(() => {
    update(v + 1);
  }, []);
  useImperativeHandle(ref, () => {
    return {
      submit: async () => {
        await formIns.validateFields();
        const fieldsValue = formIns.getFieldsValue();
        const sysPresSettings = getSysPresSettings();
        if (isElectron()) {
          await setSysPresSettings({ ...sysPresSettings, ...fieldsValue });
          try {
            await sendAsync('autofill/clear');
          } catch (e) {
            console.log(e);
          }
          const _proxyConfig: Record<string, any> = {
            useBackupEndpoint: fieldsValue.useBackupEndpoint,
          };
          if (['direct', 'system'].includes(fieldsValue.proxyMode)) {
            _proxyConfig.mode = fieldsValue.proxyMode;
          } else {
            _proxyConfig.mode = 'fixed_servers';
            _proxyConfig.proxyRules = `${fieldsValue.proxyMode}://${fieldsValue.proxyHost}:${fieldsValue.proxyPort}`;
          }
          await sendAsync('set-app-proxy', _proxyConfig).catch((e) => message.error(e.message));
          setAppProxy(_proxyConfig);
          if (sysPresSettings.appLang !== fieldsValue.appLang) {
            I18N.isCn() ? I18N.switchToEn() : I18N.switchToCn();
          }
        } else if (fieldsValue.appLang !== I18N.getLocale()) {
          // 浏览器环境下，只有语言可以修改
          I18N.isCn() ? I18N.switchToEn() : I18N.switchToCn();
        }
      },
      resetFields() {
        formIns.resetFields();
      },
    };
  });

  const getAutoUpdateText = useCallback(() => {
    if (formIns.getFieldValue('autoUpdate') === 0b11) {
      return `${I18N.t('静默安装（升级时段：')}${formIns.getFieldValue('autoInstallTimeRange')}）`;
    }
    if (formIns.getFieldValue('autoUpdate')) {
      return I18N.t('自动下载');
    }
    return I18N.t('仅提醒');
  }, [formIns]);
  return (
    <DMFormItemContext.Provider value={{ labelWidth: 150 }}>
      <Form
        form={formIns}
        requiredMark={false}
        initialValues={{
          ...sysPresSettings!,
          ...proxyConfig,
        }}
      >
        <DMFormItem
          label={I18N.t('当前设备')}
          tooltip={I18N.t('当前花漾客户端的设备标识，全局唯一')}
        >
          <CopyableText text={device?.deviceId} type={I18N.t('设备标识')}>
            <span>
              {getHostName()}
              {getDeviceSpec(device) && (
                <span style={{ marginRight: 16 }}>（{getDeviceSpec(device)}）</span>
              )}
              {I18N.t('设备ID')}：
              <span style={{ fontFamily: 'Consolas, Courier New, monospace' }}>
                {device?.deviceId}
              </span>
            </span>
          </CopyableText>
        </DMFormItem>
        {isElectron() && (
          <FormItem
            label={I18N.t('开机启动')}
            tooltip={
              <div>
                <span>
                  {I18N.t(
                    '设备是否随操作系统而自动启动花漾客户端，请注意，针对 Windows 操作系统，需要关闭账户控制（UAC）',
                  )}
                  <HelpLink
                    style={{ marginLeft: 16, color: 'inherit' }}
                    href={'/faq/app.md#auto'}
                  />
                </span>
              </div>
            }
            name="autoLaunch"
            valuePropName="checked"
          >
            {editing ? (
              <DmSwitch />
            ) : (
              <Space size={16}>
                <span>{formIns.getFieldValue('autoLaunch') ? I18N.t('开启') : I18N.t('关闭')}</span>
                {isWinPlatform() && formIns.getFieldValue('autoLaunch') && (
                  <HelpLink href={'/faq/app.md#auto'}>
                    <span>{I18N.t('需关闭 Windows UAC')}</span>
                  </HelpLink>
                )}
              </Space>
            )}
          </FormItem>
        )}
        {isElectron() && (
          <>
            <FormItem
              tooltip={I18N.t('花漾客户端在访问花漾门户服务器时的网络设置')}
              label={I18N.t('客户端网络设置')}
              name="proxyMode"
            >
              {!editing ? (
                networkTypeEnum[formIns.getFieldValue('proxyMode')]
              ) : (
                <Select>
                  <Select.Option value="direct">{I18N.t('不使用代理')}</Select.Option>
                  <Select.Option value="http">{I18N.t('使用HTTP代理')}</Select.Option>
                  <Select.Option value="socks5">{I18N.t('使用SOCKS5代理')}</Select.Option>
                  <Select.Option value="system">{I18N.t('使用操作系统代理')}</Select.Option>
                </Select>
              )}
            </FormItem>
            <DMFormItem noStyle shouldUpdate>
              {(f) => {
                const type = f.getFieldValue('proxyMode');
                if (type === 'direct' || type === 'system') {
                  return null;
                }
                if (!editing) {
                  return (
                    <DMFormItem label={I18N.t('服务器地址')}>
                      {formIns.getFieldValue('proxyHost')}:{formIns.getFieldValue('proxyPort')}
                    </DMFormItem>
                  );
                }
                return (
                  <Row gutter={16}>
                    <Col span={16}>
                      <FormItem
                        name="proxyHost"
                        label={I18N.t('服务器地址')}
                        rules={[
                          {
                            required: true,
                            message: I18N.t('请输入{{label}}', {
                              label: I18N.t('服务器地址'),
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        labelWidth={60}
                        name="proxyPort"
                        label={I18N.t('端口')}
                        rules={[
                          {
                            required: true,
                            message: I18N.t('请输入{{label}}', {
                              label: I18N.t('端口'),
                            }),
                          },
                        ]}
                      >
                        <InputNumber precision={0} min={1} max={65535} style={{ width: '100%' }} />
                      </FormItem>
                    </Col>
                  </Row>
                );
              }}
            </DMFormItem>
            <DMFormItem
              name="useBackupEndpoint"
              label={I18N.t('备用线路')}
              valuePropName="checked"
              tooltip={I18N.t('当无法用主线路访问花漾服务器时，可以启用备用线路')}
            >
              {editing ? (
                <DmSwitch />
              ) : (
                <span>
                  {formIns.getFieldValue('useBackupEndpoint') ? I18N.t('已启用') : I18N.t('未启用')}
                </span>
              )}
            </DMFormItem>
          </>
        )}
        <FormItem
          label={I18N.t('客户端语言')}
          name="appLang"
          hidden={document.querySelector('body')?.className.includes('custom-window-title-bar')}
        >
          {editing ? (
            <SwitchLocale type={'select'} />
          ) : (
            <SwitchLocale value={formIns.getFieldValue('appLang')} />
          )}
        </FormItem>
        {isElectron() && (
          <FormItem
            label={I18N.t('禁止电脑休眠')}
            name="preventSleep"
            hidden={hidePreventSleep}
            valuePropName="checked"
            tooltip={I18N.t(
              '当开启时，则电脑将不会自动进入休眠状态，该场景适用于需要定时运行RPA流程计划等场景',
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {editing ? (
              <DmSwitch />
            ) : formIns.getFieldValue('preventSleep') ? (
              I18N.t('开启')
            ) : (
              I18N.t('关闭')
            )}
          </FormItem>
        )}
        {isElectron() && (
          <FormItem
            label={I18N.t('关闭主窗口时')}
            name="exitOnClose"
            hidden={hideExitOnClose}
            tooltip={I18N.t(
              '当关闭花漾主窗口时，可以是强制退出（所有打开的花漾浏览器会自动关闭），也可以隐藏到任务栏（通过任务栏可再次激活花漾客户端）',
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {editing ? (
              <Radio.Group>
                <Radio value={true} style={{ width: 110 }}>
                  {I18N.t('退出')}
                </Radio>
                <Radio value={false}>{I18N.t('隐藏到任务栏')}</Radio>
              </Radio.Group>
            ) : formIns.getFieldValue('exitOnClose') ? (
              I18N.t('退出')
            ) : (
              I18N.t('隐藏到任务栏')
            )}
          </FormItem>
        )}
        {isElectron() && (
          <DMFormItem noStyle shouldUpdate={true}>
            {({ getFieldValue }) => (
              <FormItem
                label={I18N.t('发现新版本时')}
                name="autoUpdate"
                tooltip={
                  <>
                    <div style={{ marginBottom: 8 }}>
                      {I18N.t('仅提醒：有新版本时，在客户端左下角发出提醒，由用户自行决定是否下载')}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      {I18N.t(
                        '自动下载：自动下载最新版本，在客户端左下角发出提醒，由用户自行决定是否安装',
                      )}
                    </div>
                    <div>
                      {I18N.t(
                        '静默安装：不仅自动下载最新版本，还会在指定时间内且客户端空闲时（指没有打开任何花漾浏览器时）自动完成静默安装，适用于客户端长期值守运行RPA流程等场景',
                      )}
                    </div>
                  </>
                }
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {editing ? (
                  <Radio.Group>
                    <Radio value={0b00} style={{ minWidth: 110 }}>
                      {I18N.t('仅提醒')}
                    </Radio>
                    <Radio value={0b01} style={{ minWidth: 110 }}>
                      {I18N.t('自动下载')}
                    </Radio>
                    <Radio value={0b11} style={{ minWidth: 110 }}>
                      <Tooltip
                        title={`${I18N.t('自动升级时段：')}${getFieldValue(
                          'autoInstallTimeRange',
                        )}`}
                      >
                        {I18N.t('静默安装')}
                        <a
                          style={{ marginLeft: 8 }}
                          onClick={() => {
                            showAutoInstallTimeRangeSettingsModal({ formIns });
                          }}
                        >
                          <IconFontIcon iconName="jihua_24" size={16} />
                        </a>
                      </Tooltip>
                    </Radio>
                  </Radio.Group>
                ) : (
                  getAutoUpdateText()
                )}
              </FormItem>
            )}
          </DMFormItem>
        )}
        {isElectron() && Number(getAppBuildNumber()) > 811 && (
          <DMFormItem
            label={I18N.t('记忆已登录用户')}
            name={editing ? 'autofill' : undefined}
            hidden={hideAutoFill}
            initialValue={'password'}
            tooltip={I18N.t('用户登录信息存储在当前客户端，如有需要，可随时清理')}
          >
            {editing ? (
              <Select
                options={Object.entries(AutofillType).map(([key, value]) => {
                  return {
                    label: value,
                    value: key,
                  };
                })}
              />
            ) : (
              AutofillType[formIns.getFieldValue('autofill') || 'password']
            )}
          </DMFormItem>
        )}
        <FormItem label="" name="autoInstallTimeRange" hidden noStyle />
      </Form>
    </DMFormItemContext.Provider>
  );
}

export default forwardRef(SystemPrefForm);
