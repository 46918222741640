// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取指定团队的成员列表 GET /api/user/byTeam/${param0}/users */
export async function userByTeamByTeamIdUsersGet(
  params: {
    // query
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    /** departmentId */
    departmentId?: number;
    /** searchWord */
    searchWord?: string;
    /** 是否排除当前用户 */
    excludeMyself?: boolean;
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultPageResultUserDetailVo>(`/api/user/byTeam/${param0}/users`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 修复角色权限数据 GET /api/user/data/roleFunction/repair */
export async function userDataRoleFunctionRepairGet(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/user/data/roleFunction/repair', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 用户主动退出团队 PUT /api/user/exitTeam */
export async function userExitTeamPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/user/exitTeam', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 给用户授权账户 POST /api/user/grantShops */
export async function userGrantShopsPost(
  body: API.GrantShopsParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/user/grantShops', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改当前用户的团队昵称 PUT /api/user/teamNickname */
export async function userTeamNicknamePut(
  params: {
    // query
    /** teamNickname */
    teamNickname: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/user/teamNickname', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取指定用户详情 GET /api/user/${param0} */
export async function userByUserIdGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultUserTeamVo>(`/api/user/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取用户对所有权限的元信息 GET /api/user/${param0}/functions */
export async function userByUserIdFunctionsGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultListUserFunctionMetaVo>(`/api/user/${param0}/functions`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 更新用户权限 PUT /api/user/${param0}/functions */
export async function userByUserIdFunctionsPut(
  params: {
    // query
    /** 权限code列表，以逗号分隔 */
    functions: string;
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/functions`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 更新用户权限V2 PUT /api/user/${param0}/functionsV2 */
export async function userByUserIdFunctionsV2Put(
  params: {
    // query
    /** 权限code列表，以逗号分隔 */
    grantedFunctions: string;
    /** 权限code列表，以逗号分隔 */
    revokedFunctions: string;
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/functionsV2`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 强制授权或剥夺权限或取消 PUT /api/user/${param0}/grant */
export async function userByUserIdGrantPut(
  params: {
    // query
    /** function */
    function:
      | 'DISK_SPACE_MANAGER'
      | 'DISK_TEAM_MANAGER'
      | 'DISK_USER_MANAGER'
      | 'EXPENSE_MANAGE'
      | 'EXPENSE_VIEW'
      | 'EXTENSION_LIST'
      | 'EXTENSION_MANAGER'
      | 'FINGERPRINT_CONFIG'
      | 'FINGERPRINT_LIST'
      | 'FINGERPRINT_MANAGER'
      | 'IP_CONFIG'
      | 'IP_LIST'
      | 'IP_MANAGE'
      | 'KOL_LIST'
      | 'KOL_MANAGE'
      | 'MOBILE_CONFIG'
      | 'MOBILE_IMPORT_DELETE'
      | 'OPERATE_LOG_GET_IP'
      | 'OPERATE_LOG_GET_LOGIN'
      | 'OPERATE_LOG_GET_SHOP'
      | 'OPERATE_LOG_GET_TEAM_MANAGE'
      | 'OPERATE_LOG_MANAGE_SHOP'
      | 'RPA_CARD_MANAGER'
      | 'RPA_CREATE_DELETE'
      | 'RPA_LIST'
      | 'RPA_OPEN_API'
      | 'RPA_PLAN'
      | 'RPA_RUN'
      | 'SHOP_AUTHORIZE'
      | 'SHOP_BIND_IP_MANAGE'
      | 'SHOP_CONFIG'
      | 'SHOP_FINGERPRINT_MANAGE'
      | 'SHOP_IMPORT_DELETE'
      | 'TEAM_AUDIT'
      | 'TEAM_CRITICAL_MANAGE'
      | 'TEAM_MANAGE'
      | 'TEAM_RESOURCE_MANAGE'
      | 'TEAM_VIEW_MEMBER'
      | 'TKSHOP_BUYER_MANAGER'
      | 'TKSHOP_CREATOR_MANAGER'
      | 'TKSHOP_MANAGE'
      | 'TK_PACK_MANAGE'
      | 'TK_流程计划编排'
      | 'TK_达人管理'
      | 'TK_达人运营'
      | 'TK_达人邀约'
      | 'TK_运营辅助';
    /** granted */
    granted?: boolean;
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/grant`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取用户强制授权或剥夺列表 GET /api/user/${param0}/grantList */
export async function userByUserIdGrantListGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultListUserFunctionDto>(`/api/user/${param0}/grantList`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取用户已经授权的权限 GET /api/user/${param0}/grantedFunctions */
export async function userByUserIdGrantedFunctionsGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultListFunctionVo>(`/api/user/${param0}/grantedFunctions`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 踢出团队成员 PUT /api/user/${param0}/kickOut */
export async function userByUserIdKickOutPut(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/kickOut`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取用户角色 GET /api/user/${param0}/role */
export async function userByUserIdRoleGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultRoleVo>(`/api/user/${param0}/role`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 设置用户角色 PUT /api/user/${param0}/role */
export async function userByUserIdRolePut(
  params: {
    // query
    /** role */
    role: 'boss' | 'manager' | 'staff' | 'superadmin';
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/role`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取当前用户的团队昵称 GET /api/user/${param0}/teamNickname */
export async function userByUserIdTeamNicknameGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/user/${param0}/teamNickname`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改指定用户的团队昵称 PUT /api/user/${param0}/teamNickname */
export async function userByUserIdTeamNicknamePut(
  params: {
    // query
    /** nickname */
    nickname: string;
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/user/${param0}/teamNickname`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
